<template>
  <BaseDialog class="dialog-warpper" :dialogVisible="visible" title="侵权设置" width="750px" @closeHandle="handleClose">
    <span class="mr10">是否可查看侵权公共产品：</span>
    <el-radio v-model="radio" :label="0">否</el-radio>
    <el-radio v-model="radio" :label="1">是</el-radio>
    <template #footer>
      <loadingBtn type="primary" @click="onConfirm"> 确定 </loadingBtn>
      <el-button @click="handleClose"> 取消 </el-button>
    </template>
  </BaseDialog>
</template>

<script>
import { tortSignUpdate } from '../resellerPage/resellerAPI'

export default {
  props: {
    visible: Boolean,
    selectedData: Object,
    sup_this: Object
  },

  data() {
    return {
      radio: 0
    }
  },

  created() {
    this.radio = this.selectedData.isCanTort
  },

  methods: {
    handleClose() {
      this.$emit('update:visible', false)
    },
    async onConfirm() {
      const res = await awaitResolve(
        tortSignUpdate({
          tortSign: this.radio,
          userId: this.selectedData.id
        })
      )
      if (!res) return
      this.handleClose()
      this.sup_this.init()
      this.$message.success('操作成功')
    }
  }
}
</script>

<style lang="scss" scoped></style>
